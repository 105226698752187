<template>
  <div class="chatgpt-container">
    <!-- 左侧菜单栏 -->
    <div class="chat-sidebar">
      <div class="chat-logo">
        <el-image
          class="image-logo"
          :src="require(`../../assets/image/logoAI.png`)"
        ></el-image>
        <el-image
          class="image-exit"
          @click="loginOut"
          :src="require(`../../assets/image/exit.png`)"
          fit="fit"
        ></el-image>
      </div>
      <div class="chat-sidebar-button" @click="createNewChat">
        <i class="el-icon-plus logo"></i>
        新建聊天
      </div>
      <div class="menu">
        <span
          class="container"
          :class="{ selected: selectedIndex === 1 }"
          @click="handleClick(1)"
          >历史记录</span
        >
        <span
          class="container"
          :class="{ selected: selectedIndex === 2 }"
          @click="handleClick(2)"
          >AI工具箱</span
        >
      </div>
      <div class="chat-list" v-if="selectedIndex === 1">
        <div
          class="chat-item"
          v-for="(chat, index) in chatList"
          :key="index"
          @click="selectChat(index)"
          :class="{ active: selectedChatIndex === index }"
        >
          <i class="el-icon-chat-dot-round logo"></i>
          <div v-if="chat.isTitle" class="message-name">
            {{ chat.message_name }}
          </div>
          <input
            v-else
            class="message-input"
            @blur="addTitle(chat)"
            v-model="chat.message_name"
          />
          <i
            v-if="chat.isTitle"
            class="el-icon-edit right-logo"
            @click="editTitle(chat.chat_message_id)"
          ></i>
          <i
            v-else
            class="el-icon-close right-logo"
            @click.stop="chat.isTitle = true"
          ></i>
          <i
            class="el-icon-delete right-logo"
            @click="delTitle(chat.chat_message_id, index)"
          ></i>
        </div>
      </div>
      <div v-else class="chat-list">
        <div
          class="chat-item"
          v-for="(chat, index) in boxListArray"
          :key="index"
          @click="selectBox(chat.boxId)"
        >
          <i :class="chat.icon" class="logo"></i>
          <div class="box-name">{{ chat.name }}</div>
        </div>
      </div>
      <div class="my-personal">
        <div class="personal">
          <el-image
            class="logo"
            :src="require(`../../assets/image/myPersonal.png`)"
            fit="fit"
          ></el-image>
          <span @click="gotoPersonal()" class="title">个人中心</span>
        </div>
      </div>
    </div>
    <!-- 右侧主题部分 -->
    <div class="chat-main" v-if="isHome">
      <div class="chat-header">
        <!--
        <div class="chat-drop">
          <el-dropdown @command="modelCommand" trigger="click">
            <span class="el-dropdown-link">
              {{ modelTitle }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in modelItem"
                :key="item.key"
                :command="item"
                >{{ item.value }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="chat-drop">
          <el-dropdown @command="handleCommand" trigger="click">
            <span class="el-dropdown-link">
              {{ dropdownTitle
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in dropdownItem"
                :key="item.key"
                :command="item"
                >{{ item.value }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>  -->
<div class="top-board" v-if="showNotice">
  <span style="border-bottom: 1px solid;">祝贺哈尔滨理工大学以222%学生注册完成率领跑全国高校！期待更多院校携手并进，共创新高！</span>
  <i class="el-icon-close close-btn" @click="showNotice = false"></i>
</div>
      </div>
      <div class="chat-messages">
        <div class="title-box" v-if="selectedChat.messages.length === 0">
          <h1 class="title">AI就业平台</h1>
          <el-carousel
            height="260px"
            :autoplay="false"
            indicator-position="outside"
            trigger="click"
            @change="handleCarouselChange"
          >
            <el-carousel-item v-for="item in this.boxList.length" :key="item">
              <div class="chat-item">
                <div class="item-title">AI工具箱</div>
                <div class="item-body">
                  <el-row
                    v-for="(items, index) in boxArrayData"
                    :key="index"
                    :gutter="20"
                  >
                    <el-col v-for="item in items" :key="item.boxId" :span="8">
                      <div
                        :title="item.name"
                        class="col-item"
                        @click="selectBox(item.boxId)"
                      >
                        <i :class="item.icon" class="logo"></i>
                        <div class="box-name">{{ item.name }}</div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div
          v-else
          class="message"
          v-for="message in selectedChat.messages"
          :key="message.chat_message_dialogue_id"
        >
          <div class="answer-box f-e">
            <div class="answer">
              <span>{{ message.question }}</span>
            </div>
            <i class="el-icon-user-solid icon-user"></i>
          </div>
          <div class="answer-box">
            <el-image
              class="answer-image"
              :src="require(`../../assets/image/logomini.png`)"
            ></el-image>
            <div class="answer" style="white-space: pre-wrap;">
              <div v-if="message.answer === '......'">
                <el-image
                  :src="require(`../../assets/image/loading.gif`)"
                  fit="fit"
                ></el-image>
              </div>
              <div
                v-else-if="message.answer && message.answer.endsWith('.png')"
              >
                <el-image
                  :src="'https://' + message.answer"
                  fit="fit"
                ></el-image>
              </div>
              <span v-else>{{ message.answer }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <el-input
          class="input-box"
          @keydown.native="handleKeyDown"
          :autosize="{ minRows: 2, maxRows: 8 }"
          type="textarea"
          placeholder="在此输入您想了解的内容，按Enter发送一个消息，Shift+Enter换行"
          v-model="userMessage"
        ></el-input>
        <el-button
          class="but"
          icon="el-icon-search"
          @click="sendMessage"
          type="primary"
        ></el-button>
      </div>
      <!-- <el-image
        class="logo-image"
        :src="require(`../../assets/image/logomini.png`)"
      ></el-image> -->
    </div>
    <div class="chat-children" v-else>
      <router-view @update-value="updateValue" @getAiUser="getAiUser" v-if="is_refresh" />
    </div>
  </div>
</template>

<script>
import {
  getChatGPT,
  delChatGPT,
  getAiUserDetails,
  insertChatGPT,
  editChatGPT,
  getResumeId,
  getChainJob,
  preciseRecommend
} from "../../request/api";
import { mapMutations } from "vuex";

export default {
  inject: ["reload"],
  data() {
    return {
      chatList: [
        {
          chat_message_id: 0,
          message_name: "新的聊天",
          messages: [],
          isTitle: true,
        },
      ],
      selectedChatIndex: 0,
      userMessage: "",
      isLoading: false,
      type: "1",
      dropdownItem: [
        { key: "1", value: "文字聊天" },
        { key: "2", value: "图片聊天" },
      ],
      dropdownTitle: "文字聊天",
      modelType: "1",
      modelItem: [
        { key: "1", value: "文心一言" },
        // { key: '2', value: 'GPT4.0' }
      ],
      modelTitle: "文心一言",
      selectedIndex: 1,
      boxList: [
        [
          { boxId: "12", icon: "el-icon-s-platform", name: "职业规划课程体系" },
          { boxId: "3", icon: "el-icon-tickets", name: "MBTI职业性格测试" },
          { boxId: "5", icon: "el-icon-tickets", name: "霍兰德职业兴趣测试" },
          {
            boxId: "99,100",
            icon: "el-icon-data-analysis",
            name: "AI生涯规划推荐",
          },
          {
            boxId: "11",
            icon: "el-icon-chat-dot-round",
            name: "模拟访谈生涯人物",
          },
          { boxId: "6", icon: "el-icon-service", name: "AI职业咨询师" }
        ],
        [
          { boxId: "13", icon: "el-icon-s-order", name: "AI简历" },
          { boxId: "4", icon: "el-icon-document", name: "“职来职住”简历大赛" },
          { boxId: "9", icon: "el-icon-suitcase-1", name: "AI模拟面试" },
          { boxId: "14", icon: "el-icon-suitcase-1", name: "AI求职" },
          { boxId: "2", icon: "el-icon-chat-dot-square", name: "AI朋友圈文案" },
        ],
      ],
      isHome: true,
      boxIndex: 0,
      chain_type: "",
      getQuestionResult: [],
      is_refresh: true,
      showNotice: true
    };
  },
  computed: {
    selectedChat() {
      return this.chatList[this.selectedChatIndex];
    },
    boxArrayData() {
      const chunkSize = 3;
      const chunkedArray = this.boxList[this.boxIndex].reduce(
        (resultArray, item, index) => {
          const chunkIndex = Math.floor(index / chunkSize);
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
          }
          resultArray[chunkIndex].push(item);
          return resultArray;
        },
        []
      );

      return chunkedArray;
    },
    boxListArray() {
      return this.boxList.flatMap((innerArray) => innerArray);
    },
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
  methods: {
    handleClick(index) {
      this.selectedIndex = index;
    },
    handleCarouselChange(index) {
      this.boxIndex = index;
    },
    checkRoute() {
      this.isHome = this.$route.path === "/";
    },
    loginOut() {
      this.$confirm("是否要退出登录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.clearUser();
          localStorage.removeItem("usersinfomation");
          this.$router.push("/aiLogin");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    updateValue() {
      if (this.isHome) return;

      this.isHome = true;
      this.$router.push("/");
    },
    async checkResumeId() {
      const res = await getResumeId();
      if (res.status !== 200 || res.data.code !== "1000") {
        return this.$message.error(res.data.msg);
      }

      return res.data.data;
    },
    selectBox(boxId) {
      if (boxId === '1') {
        window.open(this.$router.resolve({ name: "Exam" }).href, "_blank");
      } else if (boxId === '2') {
        window.open(
          this.$router.resolve({ name: "FriendsCircle" }).href,
          "_blank"
        );
      } else if (boxId === '6') {
        this.isHome = false;
        if (this.$route.path === "/counselorChat") return;
        this.$router.push("/counselorChat");
      } else if (boxId === '9') {
        this.isHome = false;
        if (this.$route.path === "/interviewChat") return;
        this.$router.push("/interviewChat");
      } else if (boxId === '14') {

        this.isHome = false;
        if (this.chain_type === 2) {
          if (this.$route.path === "/firmOption") return;
          this.$router.push("/firmOption");
        } else {
          if (this.$route.path === "/workSchool") return;
          this.$router.push("/workSchool");
        }
        
      } else if (boxId === '11') {
        this.isHome = false;
        if (this.$route.path === "/privateChat") return;
        this.$router.push("/privateChat");
      } else if (boxId === '12') {
        this.isHome = false;
        if (this.$route.path === "/courseChat") return;
        this.$router.push("/courseChat");
      } else if (boxId === '13') {
        this.isHome = false;
        if (this.$route.path === "/resumeContest") return;
        this.$router.push("/resumeContest");
      } else if (boxId === '4') {
        this.isHome = false;
        this.checkResumeId()
          .then((data) => {
            if (data === false) {
              if (this.$route.path === "/resumeContestApply") return;
              this.$router.push("/resumeContestApply");
            } else {
              if (this.$route.path === "/resumeContestPreview") return;
              this.$router.push({
                path: "/resumeContestPreview",
                query: {
                  resume_id: data,
                },
              });
            }
          })
          .catch((error) => {
            return this.$message.error("错误：", error);
          });
      } else if (boxId === '3' || boxId === '5') {
        this.isHome = false;
        if (this.$route.path === "/questionnaire" || this.$route.path === '/questionResultOverall') {
          this.updateValue();
        }

        const result = this.getQuestionResult.filter(ele => boxId == ele.questionnaire_classify_id)[0];
        if (result) {
          this.$router.push(
            {
              path: '/questionResultOverall',
              query: {
                questionnaire_result_id: result.student_questionnaire_result_id,
                student_questionnaire_classify_id: boxId
              }
            }
          )
        } else {
          this.$router.push({
            path: "/questionnaire",
            query: {
              student_questionnaire_classify_id: boxId,
            },
          });
        }
        this.is_refresh = false;
        this.$nextTick(() => {
          this.is_refresh = true
        })
      } else if (boxId === '99,100') {
        this.isHome = false;
        if (this.$route.path === "/questionnaire") {
          this.updateValue();
        }

        this.$router.push({
          path: "/questionnaire",
          query: {
            student_questionnaire_classify_id: boxId,
          },
        });
      } else {
        this.$message.error("该功能暂未开放！");
      }
    },
    // 跳转个人中心
    gotoPersonal() {
      this.isHome = false;
      if (this.$route.path === "/personalFile") return;
      this.$router.push("/personalFile");
    },
    createNewChat() {
      this.updateValue();
      const newChat = {
        chat_message_id: 0,
        message_name: "新的聊天",
        messages: [],
        isTitle: true,
      };
      this.chatList.unshift(newChat);
      this.selectedChatIndex = 0;
    },
    // 修改下拉框
    handleCommand(command) {
      this.type = command.key;
      this.dropdownTitle = command.value;
    },
    // 修改下拉框
    modelCommand(command) {
      this.modelType = command.key;
      this.modelTitle = command.value;
    },
    // 识别多行文本是否提交
    handleKeyDown(event) {
      if (!event.shiftKey && event.keyCode === 13) {
        this.sendMessage();
        this.userMessage = "";
      }
    },
    ...mapMutations("m_login", ["clearUser"]),
    selectChat(index) {
      this.updateValue();
      this.selectedChatIndex = index;
    },
    async sendMessage() {
      if (this.userMessage.trim() === "") return;
      if (this.isLoading) return;

      this.isLoading = true;
      const loading = {
        answer: "......",
        chat_message_dialogue_id: Math.floor(Math.random() * 10000),
        question: this.userMessage,
      };
      this.selectedChat.messages.push(loading);
      try {
        let postData = {
          chat_message_id: this.selectedChat.chat_message_id,
          question: this.userMessage,
          type: this.type,
          modelType: this.modelType,
        };
        this.userMessage = "";
        const res = await insertChatGPT(postData);
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }
        const chatData = res.data.data;
        if (this.selectedChat.chat_message_id === 0) {
          this.selectedChat.chat_message_id = chatData.chat_message_id;
          this.selectedChat.message_name = chatData.question;
        }
        this.isLoading = false;
        this.selectedChat.messages.pop();

        if (chatData.answer && chatData.answer.endsWith(".png")) {
          this.selectedChat.messages.push(chatData);
        } else {
          this.fadeInText(chatData, chatData.answer);
        }
      } finally {
        this.isLoading = false;
      }
    },
    // 内容逐个显示
    fadeInText(chatData, answer) {
      let index = 0;
      chatData.answer = "";
      this.selectedChat.messages.push(chatData);

      function fadeInNextCharacter() {
        if (index < answer.length) {
          chatData.answer += answer[index];
          index++;
          setTimeout(fadeInNextCharacter, 50);
        }
      }
      fadeInNextCharacter();
    },
    // 失去焦点保存标题
    async addTitle(chat) {
      let postData = {
        chat_message_id: chat.chat_message_id,
        message_name: chat.message_name,
      };
      const res = await editChatGPT(postData);
      if (res.status !== 200 || res.data.code !== "1000") {
        this.$message.error(res.data.msg);
        return;
      }
      this.$message({
        type: "success",
        message: "修改成功！",
      });

      this.selectedChat.isTitle = true;
    },
    async editTitle(chat_message_id) {
      window.event.stopPropagation();
      if (chat_message_id === 0) {
        this.$message({
          type: "success",
          message: "先向我提问个问题吧！",
        });
        return;
      }

      this.chatList = this.chatList.map((ele) => {
        if (ele.chat_message_id === chat_message_id) {
          ele.isTitle = false;
        }
        return ele;
      });
    },
    async delTitle(chat_message_id, index) {
      window.event.stopPropagation();
      if (chat_message_id === 0) {
        this.$message({
          type: "success",
          message: "删除成功！",
        });
        this.chatList.splice(index, 1);
        return;
      }

      let postData = {
        chat_message_id: chat_message_id,
      };
      const res = await delChatGPT(postData);
      if (res.status !== 200 || res.data.code !== "1000") {
        this.$message.error(res.data.msg);
        return;
      }
      this.$message({
        type: "success",
        message: "删除成功！",
      });
      this.reload();
    },
    scrollContentToBottom() {
      this.$nextTick(() => {
        const contentElement = document.querySelector(".chat-messages");
        if (contentElement) {
          contentElement.scrollTop = contentElement.scrollHeight;
        }
      });
    },
    async init() {
      const res = await getChatGPT();
      if (res.status !== 200 || res.data.code !== "1000") {
        this.$message.error(res.data.msg);
        return;
      }
      if (res.data.data.length === 0) {
        this.chatList = [
          {
            chat_message_id: 0,
            message_name: "新的聊天",
            messages: [],
            isTitle: true,
          },
        ];
      } else {
        this.chatList = res.data.data;
      }
    },
    async getAiUser() {
      try {
        const res = await getAiUserDetails();
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
        }

        this.chain_type = res.data.data.getAiChain[0].type;
        this.getQuestionResult = res.data.data.getQuestionResult

        if (this.chain_type === 2) {
          try {
            const res = await getChainJob();
            if (res.status !== 200 || res.data.code !== "1000") {
              this.$message.error(res.data.msg);
              return;
            }
            const chainJobList = res.data.data.filter((ele) => ele.is_enable === 1).map(ele => ele.shop_user_job_id);

            try {
              const res = await preciseRecommend(chainJobList.join())
              if (res.status !== 200 || res.data.code !== "1000") {
                this.$message.error(res.data.msg);
                return;
              }
              const jobList = res.data.data;

              let currentTime = new Date().getTime();
              let expirationTime = currentTime + 5 * 3600 * 1000;
              localStorage.setItem('jobListMain', JSON.stringify({ ...jobList, expirationTime }))
            } catch (error) {
              this.$message.error("请求发生错误：" + error.message);
            }
          } catch(error) {
            this.$message.error("请求发生错误：" + error.message);
          }
        }
      } catch (err) {
        this.$message.error("获取数据出错了", err);
      }
    }
  },
  updated() {
    this.scrollContentToBottom();
  },
  mounted() {
    this.init();
    this.checkRoute();
    this.getAiUser();
  },
};
</script>

<style lang="less" scoped>
.chatgpt-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #ecf1f5;
  .chat-sidebar {
    flex: 1;
    background-image: url(../../assets/image/chatSidebar.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    color: #fff;
    padding: 12px 22px 12px 12px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    .chat-logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
      .image-logo {
        width: 120px;
        margin-bottom: 8px;
      }
      .image-exit {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
    .menu {
      height: 30px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-bottom: 0.5px solid #587bf2;
      margin-bottom: 20px;
      .container {
        font-size: 12px;
        height: 100%;
        line-height: 30px;
        width: 34%;
        text-align: center;
        cursor: pointer;
        border-bottom: 0.5px solid transparent;
      }
      .selected {
        border-bottom: 0.5px solid #fff;
      }
    }
    .my-personal {
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 16px;
      justify-content: space-between;
      padding: 0 20px;
      .personal {
        display: flex;
        align-items: center;
        .logo {
          margin-right: 14px;
        }
        .title {
          border-bottom: 1px solid #fff;
          cursor: pointer;
        }
      }
    }
    .chat-list {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-y: auto;
      .chat-item {
        padding: 10px;
        cursor: pointer;
        border-radius: 6px;
        height: 24px;
        line-height: 24px;
        display: flex;
        .box-name {
          width: 234px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .message-name {
          width: 186px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .message-input {
          width: 186px;
          border: 1px solid #416af1;
          background-color: #fff;
          color: #000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .right-logo {
          float: right;
          margin-left: 10px;
          line-height: 24px;
        }
        .logo {
          margin-right: 6px;
          line-height: 24px;
          color: #ffea8b;
        }
        // 当元素的背景颜色发生改变时，将以0.3秒的时间从一种颜色平滑地过渡到另一种颜色。
        transition: background-color 0.3s;
        &:hover {
          background-color: #587bf2;
        }
      }
      .active {
        background-color: #587bf2;
      }
    }
    .chat-sidebar-button {
      cursor: pointer;
      border-style: solid;
      border-width: thin;
      border-color: #fff;
      height: 40px;
      line-height: 40px;
      border-radius: 6px;
      margin-bottom: 10px;
      &:hover {
        background-color: #587bf2;
      }
      .logo {
        margin-left: 18px;
        margin-right: 6px;
      }
    }
  }
  .full-width {
    flex: 1;
  }
  .hidden {
    display: none;
  }
  .chat-children {
    flex: 5;
    padding: 12px 40px;
    background-image: url(../../assets/image/back.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -14px;
    overflow-x: auto;
  }
  .chat-main {
    flex: 5;
    padding: 12px;
    background-image: url(../../assets/image/back.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -14px;
    .chat-header {
      height: 8%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      .chat-drop {
        cursor: pointer;
        .el-dropdown-link {
          font-size: 18px;
          font-weight: bold;
        }
      }
        .top-board {
          color: #098bf6;
          font-size: 16px;
          text-decoration: none;
          display: inline-block;
          width: 100%;
          padding: 10px 40px 10px 0px;
          justify-content: center;
          align-items: center;
          display: flex;
          background-color: #fafafa;
          position: relative;
          
          .close-btn {
            position: absolute;
            right: 20px;
            cursor: pointer;
            font-size: 24px;
            color: #999;
            padding: 4px;
            border-radius: 50%;
            background: rgba(0,0,0,0.05);
            &:hover {
              color: #666;
              background: rgba(0,0,0,0.1);
            }
          }
        }
    }
    .chat-messages {
      height: 80%;
      padding: 0 12%;
      // 自减模式
      box-sizing: border-box;
      overflow-y: auto;
      .title-box {
        text-align: center;
        margin-top: 70px;	
	
        .title {
          margin-bottom: 20px;
        }
        .el-carousel {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
          border-radius: 10px;
          margin: 0;
          .el-carousel__item .chat-item {
            height: 100%;
            padding: 18px;
            text-align: left;
            display: flex;
            flex-direction: column;
            .item-title {
              margin-bottom: 10px;
              font-size: 14px;
              font-weight: bold;
            }
            .item-body {
              flex: 1;
              .el-row {
                margin-top: 10px;
                .col-item {
                  border-radius: 8px;
                  min-height: 68px;
                  background: #f3f3f3;
                  padding: 12px;
                  display: flex;
                  cursor: pointer;
                  &:hover {
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
                  }
                  .box-name {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 68px;
                    font-size: 18px;
                    margin-left: 6px;
                  }
                  @media screen and (min-width: 1014px) {
                    // 当屏幕尺寸大于768px时应用
                    .logo {
                      line-height: 68px;
                      font-size: 30px;
                    }
                  }
                  @media screen and (max-width: 1014px) {
                    // 当屏幕尺寸小于或等于768px时应用
                    .logo {
                      line-height: 68px;
                      font-size: 42px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .message {
        margin: 42px 0 20px 0;
        .f-e {
          justify-content: flex-end;
        }
        .answer-box {
          display: flex;
          align-items: flex-start;
          margin-top: 18px;
          .icon-user {
            font-size: 30px;
            color: #2a6ee9;
          }
          .answer-image {
            width: 50px;
            height: auto;
            flex-shrink: 0;
            flex-grow: 0;
            margin-right: 4px;
          }
          .answer {
            background-color: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            padding: 10px;
            color: #555;
          }
        }
      }
    }
    .box {
      height: 12%;
      padding: 0 12%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      .input-box {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        flex-grow: 1;
      }
      .but {
        height: 56px;
      }
    }
  }
}
</style>
